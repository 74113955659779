import './../template/template.js'
import './style.min.css'
// import { gsap } from './../../en/node_modules/gsap'
// import { ScrollTrigger } from './../../en/node_modules/gsap/ScrollTrigger.js'

const isFR = document.documentElement.lang == 'fr'
let gsapData = null

if (isFR) {
    try {
        gsapData = await import('./../../fr/node_modules/gsap')
    } catch (error) {
        console.log(error)
    }
} else {
    try {
        gsapData = await import('./../../en/node_modules/gsap')
    } catch (error) {
        console.log(error)
    }
}
const gsap = gsapData.default

let scrollTriggerData = null
if (isFR) {
    try {
        scrollTriggerData = await import('./../../fr/node_modules/gsap/ScrollTrigger.js')
    } catch (error) {
        console.log(error)
    }
} else {
    try {
        scrollTriggerData = await import('./../../en/node_modules/gsap/ScrollTrigger.js')
    } catch (error) {
        console.log(error)
    }
}
const ScrollTrigger = scrollTriggerData.default

// DOM elements
const video = document.querySelector('#bg-video')
const pauseButton = document.querySelector('#pause-button')
const foundationSection = document.querySelector('#foundation, #fondation')
const topBorder = foundationSection.querySelector('.top.border')
const bottomBorder = foundationSection.querySelector('.bottom.border')
const foundationLink = document.querySelector('nav .foundation-link')
const foundationTitle = foundationSection.querySelector('h2')
const weAreContent = document.querySelector('#we-are .section-content')
const weArePanels = weAreContent.querySelectorAll('.media-container')
const weAreFirstH2 = document.querySelector('#we-are .first')
const weAreConstantH2 = document.querySelector('#we-are .constant')
const weAreVariableH2 = document.querySelector('#we-are .variable')
const weAreAppearances = document.querySelector('#we-are .appearances')
const appearancesArrow = weAreAppearances.querySelector('.arrow-container')

let texts = [
    {
        text: 'a charity',
        textFR: 'une charité',
        progress: 0,
    },
    {
        text: 'leaders',
        textFR: 'des leaders',
        progress: 0,
    },
    {
        text: 'the future',
        textFR: 'le futur',
        progress: 0,
    },
    {
        text: 'here',
        textFR: 'là',
        progress: 0,
    },
    {
        text: ' for you',
        textFR: ' pour vous',
        progress: 0,
    },
]

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({
    invalidateOnRefresh: true,
    scrub: true,
})

// Start video on user interaction if suspended on load
video.addEventListener('suspend', () => {
    window.addEventListener('click', startVideo, { once: true })
    window.addEventListener('touchstart', startVideo, { once: true })
})
function startVideo() {
    // Don't start video if user paused
    if (pauseButton.classList.contains('paused')) return

    video.play()
}

// Play video
pauseButton.addEventListener('click', () => {
    if (video.paused) {
        video.play()
        pauseButton.title = 'Pause'
    } else {
        video.pause()
        pauseButton.title = isFR ? 'Lecture' : 'Play'
    }
    pauseButton.classList.toggle('paused')
})

// gsap.set('.huge-title', {
//     x: 0,
//     xPercent: 0,
//     y: 0,
//     yPercent: -50,
//     width: '100%',
//     left: 0,
//     top: '50%',
// })

// Scale down video container
gsap.to('.vid-container', {
    scrollTrigger: {
        trigger: '#top',
        start: '0% top',
        end: '60% top',
        // pin: '.huge-title',
        // anticipatePin: 1,
        // markers: true,
        onLeave: () => {
            foundationLink.classList.add('selected')
        },
        onEnterBack: () => {
            foundationLink.classList.remove('selected')
        },
    },
    scale: 0.94,
    borderRadius: '5vmin',
})

new ScrollTrigger({
    trigger: foundationSection,
    start: '0% 0%',
    end: '100% 100%',
    // markers: true,
    onEnter: () => {
        foundationSection.classList.add('shadow')
        topBorder.style.transform = 'scale(1.5, 1)'
        bottomBorder.style.transform = 'scale(1.5, 1)'
    },
    onLeaveBack: () => {
        foundationSection.classList.remove('shadow')
        topBorder.style.transform = 'scale(1.5, 0)'
        bottomBorder.style.transform = 'scale(1.5, 0)'
    },
})
// for (let section of [foundationSection, weAreSection, impactSection]) {
//     let topBorder = section.querySelector('.top.border')
//     let bottomBorder = section.querySelector('.bottom.border')

//     new ScrollTrigger({
//         trigger: section,
//         start: '0% 1%',
//         end: '0% 10%',
//         markers: true,
//         onEnter: () => {
//             section.classList.add('shadow')
//             topBorder.style.transform = 'scale(1.5, 1)'
//             bottomBorder.style.transform = 'scale(1.5, 1)'
//         },
//         onLeaveBack: () => {
//             section.classList.remove('shadow')
//             topBorder.style.transform = 'scale(1.5, 0)'
//             bottomBorder.style.transform = 'scale(1.5, 0)'
//         },
//     })
// }

gsap.to('.icon', {
    keyframes: {
        '0%': { opacity: 0, y: 0, yPercent: 0 },
        '10%': { opacity: 0.04, ease: 'linear' },
        '100%': { opacity: 0.04, y: 0, yPercent: -50, ease: 'linear' },
    },
    ease: 'linear',
    scrollTrigger: {
        trigger: foundationSection,
        start: '0% 10%',
        end: '50% 0%',
        // markers: true,
    },
})

gsap.fromTo(
    foundationTitle,
    {
        autoAlpha: 0,
        // y: 0,
        // yPercent: -100,
        // x: 0,
        // xPercent: 0,
        scale: 1.4,
    },
    {
        autoAlpha: 1,
        // y: 0,
        // yPercent: -100,
        // x: 0,
        // xPercent: 0,
        scale: 1,
        ease: 'power2.out',
        scrollTrigger: {
            trigger: foundationSection,
            start: '0% 50%',
            end: '0% 20%',
        },
        ease: 'linear',
    }
)

gsap.to(weAreVariableH2, {
    autoAlpha: 1,
    duration: 0.25,
    ease: 'linear',
    scrollTrigger: {
        trigger: weAreContent,
        start: '0% 20%',
        toggleActions: 'restart none none reverse',
        scrub: false,
        // markers: true,
    },
})

for (let i = 0; i < weArePanels.length; i++) {
    let panel = weArePanels[i]
    let nextPanel = weArePanels[i + 1]
    let textObj = texts[i]
    let textContent = isFR ? textObj.textFR : textObj.text

    if (i < weArePanels.length - 2) {
        gsap.fromTo(
            textObj,
            {
                progress: 0,
            },
            {
                progress: textContent.length,
                yoyo: true,
                repeat: 1,
                repeatDelay: 10,
                duration: 2,
                ease: `steps(${textContent.length})`,
                scrollTrigger: {
                    trigger: panel,
                    start: '0% 90%',
                    endTrigger: nextPanel,
                    end: '0% 105%',
                    // markers: true,
                    onRefresh: () => {
                        for (let textThing of texts) {
                            if (textThing.progress > 0) {
                                // console.log(textObj.progress)
                                let existingText = isFR ? textThing.textFR : textThing.text
                                weAreVariableH2.textContent = existingText.substring(0, textThing.progress)
                            }
                        }
                    },
                },
                onUpdate: () => {
                    weAreVariableH2.textContent = textContent.substring(0, textObj.progress)
                },
            }
        )
    } else if (i == weArePanels.length - 2) {
        let nextTextObj = texts[i + 1]
        let nextTextContent = isFR ? nextTextObj.textFR : nextTextObj.text

        let lastTextTl = new gsap.timeline({
            scrollTrigger: {
                trigger: panel,
                start: '0% 90%',
                endTrigger: nextPanel,
                end: '0% 80%',
                // markers: true,
            },
        })
        lastTextTl.fromTo(
            textObj,
            {
                progress: 0,
            },
            {
                progress: textContent.length,
                duration: 2,
                ease: `steps(${textContent.length})`,
                onUpdate: () => {
                    weAreVariableH2.textContent = textContent.substring(0, textObj.progress)
                },
            }
        )
        lastTextTl.to(
            weAreFirstH2,
            {
                autoAlpha: 0,
                duration: 2,
            },
            '<'
        )
        lastTextTl.to(
            nextTextObj,
            {
                progress: nextTextContent.length,
                duration: 3,
                ease: `steps(${nextTextContent.length})`,
                onUpdate: () => {
                    weAreVariableH2.textContent = textContent + nextTextContent.substring(0, nextTextObj.progress)
                },
            },
            '>10'
        )
    } else {
        gsap.set([weAreVariableH2, weAreConstantH2], {
            autoAlpha: 0,
            // duration: 0.3,
            // ease: 'linear',
            scrollTrigger: {
                trigger: panel,
                start: '50% 0%',
                toggleActions: 'restart none none reverse',
                scrub: false,
            },
        })
    }
}

// weArePanels.forEach((panel, index) => {
//     let textObj = texts[index]
//     gsap.to(textObj, {
//         progress: textObj.text.length,
//         scrollTrigger: {
//             trigger: panel,
//             start: '0% 100%',
//             end: '100% 100%',
//             markers: true,
//         },
//         onUpdate: () => {
//             weAreVariableH2.textContent = textObj.text.substring(0, textObj.progress)
//         },
//     })
// })

// let weAreTl = new gsap.timeline({
//     paused: true,
//     scrollTrigger: {
//         trigger: weAreContent
//     }
// })

// const resizeObserver = new ResizeObserver((entries) => {
//     ScrollTrigger.refresh()
//     console.log('refreshed')
// })

// const lazyImages = document.querySelectorAll("img[loading='lazy']")
// lazyImages.forEach((img) => {
//     resizeObserver.observe(img)
// })

// Scroll button for the media appearances section
appearancesArrow.addEventListener('click', () => {
    weAreAppearances.scrollTo(weAreAppearances.clientWidth * 0.65 + weAreAppearances.scrollLeft, 0)
})
